/*----------------------------------------------------------------------------*
	$TEXT-ICON
*----------------------------------------------------------------------------*/

/* ^.o-text-icon
-----------------------------------------------------------------------------*/

.o-text-icon
	display: flex
	align-items: center

	&:before
		margin-right: 10px
		font-size: 2.2rem

/* ^Fin - .o-text-icon
-----------------------------------------------------------------------------*/
