/*----------------------------------------------------------------------------*
	$C-SLIDER
*----------------------------------------------------------------------------*/


/* ^.c-slider-login
-----------------------------------------------------------------------------*/

.c-slider-login
	width: 100%
	height: 100%

	+max('xl')
		display: none

	.slick-list,
	.slick-track
		height: 100%


.c-slider-login__item
	height: 100%
	background-repeat: no-repeat
	background-position: right center
	background-size: cover


/* ^Fin - .c-slider-login
-----------------------------------------------------------------------------*/





/* ^.c-slider-1
-----------------------------------------------------------------------------*/

.c-slider-1
	width: 100%


	.slick-dots
		position: absolute
		bottom: -60px
		display: flex !important
		justify-content: center
		width: 100%

		li
			margin: 5px

			&.slick-active
				button
					background: $base-color
					border-color: $base-color

		button
			btn()
			padding: 0
			width: 15px
			height: 15px
			border-radius: 50%
			border: 1px solid #bdbdbd
			background: #fff
			text-indent: -9999px

			&:hover
				background: $base2-color
				border-color: $base2-color


.c-slider-1__container
	+min('xl')
		display: flex
		flex-wrap: wrap
		align-items: center


.c-slider-1__img
	flex: none

	img
		display: block
		width: 100%

		+min('xl')
			max-width: 470px


.c-slider-1__box
	+min('xl')
		flex: 1
		padding-left: 30px
		padding-right: 15px

	+max('xl')
		padding-top: 15px


.c-slider-1__heading
	margin-bottom: 18px
	font-size: 2.3rem
	color: $base2-color
	font-weight: $bold


.c-slider-1__p
	margin-bottom: 20px
	line-height: 1.5


/* ^Fin - .c-slider-home
-----------------------------------------------------------------------------*/
