/*----------------------------------------------------------------------------*
	$O-TEXT
*----------------------------------------------------------------------------*/


/* ^.o-text-permiso
-----------------------------------------------------------------------------*/

.o-text-permiso
	position: absolute
	display: flex
	flex-wrap: wrap
	top: 0
	right: 0
	padding: 8px 10px
	border-bottom: 1px solid #ececec
	border-left: 1px solid #ececec
	border-radius: 0 0 0 5px
	background: #fff

	span
		display: inline-flex
		margin: 3px
		border-radius: 3px
		padding: 1px 5px
		// background: #dbb582
		font-weight: $bold
		text-align: center
		font-size: 1.4rem
		color: #fff


/* ^Fin - .o-text-permiso
-----------------------------------------------------------------------------*/
