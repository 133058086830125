/*----------------------------------------------------------------------------*
	$C-FORM
*----------------------------------------------------------------------------*/


/* ^.c-form-login
-----------------------------------------------------------------------------*/

.c-form-login
	position: relative
	z-index: 20
	width: 100%
	max-width: 340px
	// background: #ccc

	+min(1501px)
		max-width: 380px


.c-form-login__logo
	margin-bottom: 40px


.c-form-login__heading
	margin-bottom: 40px
	text-align: center
	font-weight: $bold
	font-size: 2rem
	color: #2f3b4b

	+min(1291px)
		font-size: 2.2rem

	strong
		color: #2f3b4b
		font-size: 2.5rem

	span
		display: block


.c-form-login__row
	margin-bottom: 0


.c-form-login__row--btn
	margin-top: 30px


.c-form-login__row--field
	@extends .c-form-login__row
	padding-bottom: 5px
	border-bottom: 2px solid #2f3b4b


.c-form-login__row--field-v2
	@extends .c-form-login__row--field


.c-form-login__notification
	margin-bottom: 20px


// a
.c-form-login__text-password
	display: inline-block
	line-height: 1.2
	border-bottom: 1px solid #2f3b4b
	color: $base2-color
	font-size: 1.4rem

	+max('m')
		font-size: 1.2rem

	&:hover
		color: $base-color
		border-bottom-color: $base-color


.c-form-login__text-check
	font-weight: $semi-bold !important
	font-size: 1.8rem !important
	color: $base2-color !important


.c-form-login__box-2
	padding: 25px 0
	border-bottom: 1px solid $base2-color
	text-align: center

	a
		font-weight: $semi-bold
		color: $base2-color

		&:hover
			color: $base-color

		&:before
			margin-right: 7px



.c-form-login__footer
	padding-top: 25px
	text-align: center


.c-form-login__copy
	margin-bottom: 10px
	color: $base2-color
	font-size: 1.3rem


.c-form-login__text-intro
	text-align: center
	font-size: 1.5rem
	line-height: 1.4


.c-form-login__error
	margin-bottom: 25px

/* ^Fin - .c-form-login
-----------------------------------------------------------------------------*/





/* ^.c-form-1
-----------------------------------------------------------------------------*/

.c-form-1
	width: 100%
	max-width: 600px
	margin: 0 auto
	padding-bottom: 30px


.c-form-1--v2
	@extends .c-form-1
	max-width: 400px


.c-form-1__container
	overflow: hidden
	margin-bottom: 15px
	padding: 20px 15px 0 15px
	border-radius: 7px
	box-shadow: 0 2px 3px rgba(#000, .06)
	background: #fff

	+min('m')
		padding: 20px 20px 0 20px


.c-form-1__row
	+min('l')
		grid()
		margin-bottom: 20px


.c-form-1__col
	+min('l')
		col(1,2)


.c-form-1__field
	+max('l')
		margin-bottom: 15px


.c-form-1__field--v2
	@extends .c-form-1__field
	margin-bottom: 20px


.c-form-1__label
	display: block
	margin-bottom: 5px
	font-size: 1.5rem
	color: $base2-color
	font-weight: $semi-bold


.c-form-1__notification
	margin-bottom: 15px


.c-form-1__btn
	text-align: center


.c-form-1__intro
	margin-bottom: 20px
	font-size: 1.5rem
	font-weight: $medium


.c-form-1__box
	display: none

/* ^Fin - .c-form-1
-----------------------------------------------------------------------------*/
