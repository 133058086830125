/*----------------------------------------------------------------------------*
	$O-FORM
*----------------------------------------------------------------------------*/

/* ^.o-form-input
-----------------------------------------------------------------------------*/

// input[type=text|password|number|etc]
.o-form-field
	appearance: none
	width: 100%
	height: 40px
	padding: 0 15px
	border: 2px solid #C8CDD1
	border-radius: 5px
	// box-shadow: 0 0 5px 0 rgba(#000, .10)
	outline: 0
	background: #fff
	font-size: 1.6rem
	font-weight: $normal
	color: $base-text-color
	placeholder(#94999D)
	transition: all .3s ease
	// placeholder(color @color,)

	&:hover
		border-color: darken(#C8CDD1, 20%)

	&:focus
		border-color: darken(#C8CDD1, 50%)
		background: #fff
		color: $base-text-color
		// placeholder(color #D0D0D0,)


.o-form-field--textarea
	@extend .o-form-field
	height: 150px
	padding: 13px

/* ^Fin - .o-form-input
-----------------------------------------------------------------------------*/





/* ^.o-form-field-icon
-----------------------------------------------------------------------------*/

.o-form-field-icon
	position: relative

	&:before
		position: absolute
		top: 50%
		left: 0
		transform: translateY(-50%)
		font-size: 2rem
		color: $base2-color

	.o-form-field-2
		padding-left: 30px


.o-form-field-icon--v2
	@extends .o-form-field-icon
	padding-right: 150px

	+max('m')
		padding-right: 130px

.o-form-field__text
	position: absolute
	bottom: 4px
	right: 0

/* ^Fin - .o-form-field-icon
-----------------------------------------------------------------------------*/





/* ^.o-form-field-2
-----------------------------------------------------------------------------*/

.o-form-field-2
	appearance: none
	width: 100%
	height: 40px
	padding: 0 20px
	border: none
	outline: 0
	background: #fff
	font-size: 1.7rem
	font-weight: $semi-bold
	color: $base2-color
	+placeholder($base2-color)
		font-weight: $semi-bold


/* ^Fin - .o-form-field-2
-----------------------------------------------------------------------------*/





/* ^.o-form-check
-----------------------------------------------------------------------------*/

// Contenedor del check
.o-form-check
	display: flex


// input
.o-form-check__campo
	display: none

	& + label,
	& + span + label
		position: relative
		display: block
		width: 20px
		height: @width
		margin-right: 12px
		font-family: 'icomoon' !important;
		font-size: 2rem
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		font-smoothing()
		color: $base2-color
		cursor: pointer

		& + span
			padding-top: 1px
			font-size: 1.6rem
			color: $base2-color
			font-weight: $medium

		&:hover
			color: lighten($base2-color, 30%)

	// Icono checkbox
	&[type="checkbox"] + label,
	&[type="checkbox"] + span + label
		&:before
			content: "\ee6f"

	// Icono radio
	&[type="radio"] + label,
	&[type="radio"] + span + label
		&:before
			content: "\ee73"

	// Cuando el estado es checked
	&:checked
		& + label
			color: $base-color

			&:hover
				&:before
					color: darken($base-color, 6)

		// Icono checkbox
		&[type="checkbox"] + label
			&:before
				content: "\ee6e"

		// Icono radio
		&[type="radio"] + label
			&:before
				content: "\ee76"

/* ^Fin - .o-form-check
-----------------------------------------------------------------------------*/





/* ^Select
	Extendido desde vendor/selectize/selectize.styl
-----------------------------------------------------------------------------*/

.selectize-input
	@extend .o-form-field
	position: relative
	z-index: 1
	overflow: hidden
	display: inline-flex !important
	align-items: center
	padding: 0 30px 0 20px

	> input
		width: 100% !important
		padding-bottom: 4px
		border: none !important
		font-size: 1.6rem !important
		font-weight: $normal !important
		color: $base-text-color !important
		placeholder(#94999D)

	> .item
		flex: none
		text-overflow: ellipsis
		white-space:  nowrap
		overflow: hidden
		padding-bottom: 3px
		font-size: 1.6rem
		font-weight: $normal
		color: $base-text-color


// Desplegable con opciones
.selectize-dropdown
	position: absolute
	overflow: hidden
	z-index: 10
	top: calc(100% + 2px) !important
	border-radius: 5px
	border: 1px solid rgba(#000, .2)
	box-shadow: 0 2px 15px 0 rgba(#000, .20)
	background: #fff
	font-size: 1.5rem

	.active
		background-color: #f2f4f6

/* ^Fin - Select
-----------------------------------------------------------------------------*/





/* ^.o-form-link
-----------------------------------------------------------------------------*/

// a
.o-form-link
	color: #868C91

	&:hover
		color: $base-color

/* ^Fin - .o-form-link
-----------------------------------------------------------------------------*/





/* ^o-form-error
-----------------------------------------------------------------------------*/

span.o-form-error
	display: block
	padding-left: 20px
	font-size: 1.5rem
	color: $base-color


span.o-form-error-login
	display: block
	padding-top: 6px
	padding-left: 30px
	font-size: 1.4rem
	color: #f43838


.o-form-error-contact
	display: block
	padding-top: 5px
	color: #eb3535
	font-size: 1.3rem

/* ^Fin - o-form-error
-----------------------------------------------------------------------------*/
