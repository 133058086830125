/*----------------------------------------------------------------------------*
	$REPRODUCTOR
*----------------------------------------------------------------------------*/


/* ^Reproductor video
-----------------------------------------------------------------------------*/

.o-player-wrap
	width: 100%
	position: relative
	padding: 56.25% 0 0 0
	// box-shadow: 0 2px 20px rgba(#000, .7)

	iframe
		position: absolute
		width: 100% !important
		height: 100%
		top:0
		left: 0

/* ^Fin - Reproductor video
-----------------------------------------------------------------------------*/





/* ^.o-player-video
-----------------------------------------------------------------------------*/

.o-player-video
	position: relative
	width: 100% !important
	border-radius: 7px
	padding: 58% 0 0 0
	background: rgba(#0C4A38, .8)

	&:before
		position: absolute
		top: 50%
		left: 50%
		color: $base4-color
		font-size: 7rem
		transform: translate(-50%, -50%)

/* ^Fin - .o-player-video
-----------------------------------------------------------------------------*/
