/*----------------------------------------------------------------------------*
	$BUTTONS
*----------------------------------------------------------------------------*/


/* ^btn
-----------------------------------------------------------------------------*/

// Boton por defecto
.o-btn
	btn()
	display: inline-flex
	align-items: center
	height: 40px
	padding: 0 22px
	border-radius: 7px
	border: 2px solid $base-color
	background: transparent
	font-weight: $medium
	font-size: 1.8rem
	color: $base-color
	transition: all .2s ease
	// box-shadow: 0 3px 0 0 rgba(#000, .4)

	&:hover
		background: $base-color
		color: #fff


.o-btn--upload
	@extend .o-btn

	&:before
		margin-right: 10px

	[type="file"]
		height: calc(100% + 5px)
		cursor: pointer




/* ^Fin - btn
-----------------------------------------------------------------------------*/





/* ^.o-btn-2
-----------------------------------------------------------------------------*/

.o-btn-2
	@extends .o-btn
	width: 100%
	justify-content: center
	height: 60px
	border: none
	background: $base-color
	color: #fff
	font-weight: $bold
	font-size: 2rem

	&:hover
		background: $base2-color

/* ^Fin - .o-btn-2
-----------------------------------------------------------------------------*/





/* ^.o-btn-3
-----------------------------------------------------------------------------*/

.o-btn-3
	@extends .o-btn
	height: 32px
	padding: 0 15px
	background: #000
	color: #fff
	font-size: 1.3rem
	font-weight: $semi-bold

	&:hover
		background: #404040

/* ^Fin - .o-btn-3
-----------------------------------------------------------------------------*/





/* ^.o-btn-4
-----------------------------------------------------------------------------*/

.o-btn-4
	@extends .o-btn
	color: #fff
	font-size: 1.4rem
	font-weight: $semi-bold

	&:hover
		background: $base-color

/* ^Fin - .o-btn-4
-----------------------------------------------------------------------------*/





/* ^.o-btn-5
-----------------------------------------------------------------------------*/

.o-btn-5
	@extends .o-btn
	height: 44px
	border: none
	background: $base-color
	color: #fff
	font-weight: $semi-bold
	font-size: 1.5rem

	&:hover
		background: $base2-color

		i
			color: $base-color

	i
		margin-right: 10px
		font-size: 2.2rem
		transition: all .2s ease


.o-btn-5--v2
	@extends .o-btn-5
	height: 40px
	padding: 0 30px

/* ^Fin - .o-btn-5
-----------------------------------------------------------------------------*/





/* ^.o-btn-6
-----------------------------------------------------------------------------*/

.o-btn-6
	@extends .o-btn
	border: none
	background: $base2-color
	color: #fff
	font-weight: $semi-bold
	font-size: 1.5rem

	&:hover
		background: $base-color


/* ^Fin - .o-btn-6
-----------------------------------------------------------------------------*/





/* ^.o-btn-7
-----------------------------------------------------------------------------*/

.o-btn-7
	@extends .o-btn
	border: none
	background: $base3-color
	color: #fff
	font-weight: $semi-bold
	font-size: 1.5rem

	&:hover
		background: $base-color


/* ^Fin - .o-btn-7
-----------------------------------------------------------------------------*/





/*	^o-btn-cerrar 'cerrar popUps'
-------------------------------------------------------------*/

.o-btn-cerrar
	btn()
	position: absolute
	top: -25px
	right: -25px
	padding: 0
	background: transparent
	font-size: 2.5rem
	color: #fff

	+max('m')
		top: -35px
		left: 50%
		margin-left: -1.25rem
		right: auto

	span
		display: none

/*	^Fin - o-btn-cerrar 'cerrar popUps'
-------------------------------------------------------------*/
