/*----------------------------------------------------------------------------*
	$C-LILST
*----------------------------------------------------------------------------*/


/* ^.c-list-1
-----------------------------------------------------------------------------*/

// ul
.c-list-1
	display: flex
	justify-content: center
	align-items: center

	li
		display: flex
		align-items: center
		height: 16px
		border-left: 1px solid $base2-color
		padding: 0 7px

		&:first-child
			border-left: 0

		a
			display: inline-block
			font-size: 1.3rem
			color: $base2-color

			&:hover
				color: $base-color

/* ^Fin - .c-list-1
-----------------------------------------------------------------------------*/





/* ^.c-list-2
-----------------------------------------------------------------------------*/

// ul
.c-list-2

	li
		display: flex
		justify-content: space-between
		align-items: center
		padding: 10px 0
		border-bottom: 2px solid #425066
		color: #e0e0e0
		font-size: 1.4rem

		&:first-child
			padding-top: 0

		&:last-child
			border-bottom: none

		a
			flex: 1
			margin-right: 15px
			color: #fff

			&:hover
				color: $base-color

		i
			flex: none
			color: $base-color
			font-size: 1.8rem

/* ^Fin - .c-list-2
-----------------------------------------------------------------------------*/





/* ^.c-list-3
-----------------------------------------------------------------------------*/

.c-list-3
	width: 100%
	display: flex
	flex-wrap: wrap

	+max('l')
		max-width: 400px
		margin: 0 auto


.c-list-3__item
	display: flex
	flex-wrap: wrap
	flex-direction: column
	width: 100%
	margin-bottom: 30px
	box-shadow: 0 2px 5px rgba(#000, .1)
	background: #fff

	+min('l')
		width: calc(100% / 2 - 30px)
		margin: 0 15px 30px 15px

	+min('xl')
		width: calc(100% / 3 - 30px)


.c-list-3__img
	flex: none
	width: 100%

	img
		display: block


.c-list-3__box
	flex: 1
	display: flex
	flex-wrap: wrap
	padding: 15px


.c-list-3__content
	width: 100%


.c-list-3__btn
	width: 100%
	margin-top: auto
	text-align: center


.c-list-3__heading
	margin-bottom: 15px
	color: $base2-color
	font-weight: $bold
	text-align: center
	font-size: 1.8rem


.c-list-3__p
	margin-bottom: 20px
	text-align: center
	font-size: 1.4rem
	line-height: 1.4


/* ^Fin - .c-list-3
-----------------------------------------------------------------------------*/





/* ^Sub Section
-----------------------------------------------------------------------------*/

.c-list-4__item
	margin-bottom: 15px
	box-shadow: 0 2px 3px rgba(#000, .06)
	background: #fff

	+min(561px)
		display: flex
		flex-wrap: wrap

	&:last-child
		margin-bottom: 0


.c-list-4__img
	flex: none

	+max('l')
		display: flex
		justify-content: center
		align-items: center
		background: $base2-color

	img
		display: block
		max-width: 270px

		+max('m')
			width: 100%
			max-width: none


.c-list-4__box
	position: relative
	flex: 1
	padding: 20px


.c-list-4__heading
	margin-bottom: 5px
	color: $base2-color
	font-size: 2rem
	font-weight: $bold


.c-list-4__time
	margin-bottom: 15px
	color: $base2-color


.c-list-4__p
	margin-bottom: 18px
	font-size: 1.5rem
	line-height: 1.3

/* ^Fin - Sub Section
-----------------------------------------------------------------------------*/





/* ^.c-list-5
-----------------------------------------------------------------------------*/

.c-list-5
	border-bottom: 1px solid #bcbcbf

.c-list-5__item
	margin-bottom: 25px


.c-list-5__header
	display: flex
	flex-wrap: wrap
	align-items: center
	margin-bottom: 15px


.c-list-5__img
	flex: none
	margin-right: 20px

	img
		display: block
		max-width: 80px
		border-radius: 50%


.c-list-5__box
	flex: 1


.c-list-5__name
	margin-bottom: 2px
	color: $base2-color
	font-weight: $bold
	font-size: 1.6rem
	line-height: 1.1


.c-list-5__profession
	font-size: 1.4rem


.c-list-5__bio
	margin-bottom: 15px
	font-size: 1.4rem


.c-list-5__btn
	color: $base-color
	font-weight: $bold
	font-size: 1.5rem

	&:hover
		color: $base-color
		text-decoration: underline


/* ^Fin - .c-list-5
-----------------------------------------------------------------------------*/





/* ^.c-list-6
-----------------------------------------------------------------------------*/

.c-list-6__item
	display: block
	padding: 15px
	margin-bottom: 15px
	border-radius: 10px
	border-left: 12px solid #9e9e9e
	border-right: 1px solid transparent
	border-top: 1px solid transparent
	border-bottom: 1px solid transparent
	background: #eeeeee

	&:hover
		border-left: 12px solid $base2-color
		border-right-color: $base2-color
		border-top-color: $base2-color
		border-bottom-color: $base2-color


	&.active
		border-left: 12px solid $base-color
		border-right-color: $base-color
		border-top-color: $base-color
		border-bottom-color: $base-color

.c-list-6__heading
	margin-bottom: 10px
	color: $base2-color
	font-size: 2rem
	font-weight: $bold


.c-list-6__p
	font-size: 1.5rem
	color: $base2-color

/* ^Fin - .c-list-6
-----------------------------------------------------------------------------*/







/* ^.c-list-7
-----------------------------------------------------------------------------*/

.c-list-7__item
	margin-bottom: 10px

	&.active
		.c-list-7__header
			background: $base-color
			color: #fff

			&:hover
				background: darken($base-color, 10%)

			i
				color: #fff


.c-list-7__header
	display: flex
	align-items: center
	padding: 10px 16px
	border-radius: 7px
	background: $base2-color
	text-transform: uppercase
	font-weight: $bold
	color: #fff
	transition: all .2s ease
	cursor: pointer

	&:hover
		background: lighten($base2-color, 15%)

	i
		margin-right: 10px
		font-size: 2rem


.c-list-7__info
	display: none

	p
		font-size: 1.5rem


.c-list-7__info-box
	padding: 20px 10px

	+min('m')
		padding: 20px 0 20px 20px


.c-list-7__recurso
	display: flex
	flex-wrap: wrap
	justify-content: space-between
	align-items: center
	padding: 13px 18px
	margin-bottom: 10px
	border-radius: 5px
	border: 1px solid transparent
	background: #fff

	&:hover
		border-color: lighten($base2-color, 15%)

	&:last-child
		margin-bottom: 0


.c-list-7__name
	color: $base2-color
	font-size: 1.5rem

	[class*="icon"]
		display: inline-flex
		margin-right: 10px
		font-size: 2rem


.c-list-7__time
	color: #878787
	font-size: 1.3rem

	[class*="icon"]
		display: inline-flex
		margin-right: 3px


/* ^Fin - .c-list-7
-----------------------------------------------------------------------------*/





/* ^.c-list-8
-----------------------------------------------------------------------------*/

.c-list-8
	+min('xl')
		display: flex
		flex-wrap: wrap

	+max('xl')
		width: 100%
		max-width: 400px
		margin: 0 auto

.c-list-8__item
	width: 100%
	margin-bottom: 25px
	box-shadow: 0 2px 3px rgba(#000, .06)
	background: #fff

	+min('xl')
		width: calc(100% / 2 - 12.5px)

		&:nth-child(2n+1)
			margin-right: 25px


.c-list-8__img
	img
		display: block


.c-list-8__box
	padding: 15px


.c-list-8__heading
	margin-bottom: 5px
	color: $base2-color
	font-weight: $bold
	font-size: 1.9rem


.c-list-8__p
	margin-bottom: 15px
	font-size: 1.5rem


.c-list-8__data
	margin-bottom: 20px


.c-list-8__comments
	font-size: 1.3rem
	color: $base2-color

	&:hover
		text-decoration: underline
		color: $base2-color

	i
		margin-right: 5px


/* ^Fin - .c-list-8
-----------------------------------------------------------------------------*/





/* ^.c-list-9
-----------------------------------------------------------------------------*/

.c-list-9__item
	margin-bottom: 25px
	box-shadow: 0 2px 3px rgba(#000, .06)
	background: #fff


.c-list-9__header
	+min('m')
		display: flex

.c-list-9__date
	flex: none
	display: flex
	align-items: center
	flex-wrap: wrap
	align-content: center
	width: 90px
	height: 80px
	background: $base2-color
	color: #fff
	font-weight: $bold
	text-align: center
	line-height: 1

	+max('m')
		width: 100%



.c-list-9__mes
	width: 100%


.c-list-9__day
	width: 100%
	font-size: 4.4rem


.c-list-9__box
	flex: 1
	display: flex
	flex-wrap: wrap
	align-items: center
	align-content: center
	padding: 0 20px
	line-height: 1

	+max('m')
		padding: 20px


.c-list-9__name
	width: 100%
	margin-bottom: 5px
	color: $base2-color
	font-weight: $bold
	font-size: 2.1rem


.c-list-9__time
	width: 100%


.c-list-9__box-2
	padding: 15px


.c-list-9__p
	font-size: 1.5rem
	line-height: 1.4


.c-list-9__btn
	> *
		margin-right: 15px

		+max('m')
			margin-bottom: 10px

			&:last-child
				margin-bottom: 0

		&:last-child
			margin-right: 0

/* ^Fin - .c-list-9
-----------------------------------------------------------------------------*/





/* ^.c-list-10
-----------------------------------------------------------------------------*/

.c-list-10
	grid()
	margin-bottom: 30px


.c-list-10__item
	col(1,4)
	background: #fff

	&:hover
		.c-list-10__heading
			background: $base-color

		.c-list-10__img
			img
				transform: scale(1.2)


.c-list-10__heading
	padding: 7px 15px
	background: $base2-color
	color: #fff
	text-align: center
	font-weight: $semi-bold
	transition: all .2s ease


.c-list-10__img
	overflow: hidden

	img
		display: block
		transition: all .2s ease

/* ^Fin - .c-list-10
-----------------------------------------------------------------------------*/





/* ^.c-list-11
-----------------------------------------------------------------------------*/

.c-list-11
	grid()
	margin-bottom: 30px


.c-list-11__item
	col(1,4)
	box-shadow: 0 2px 3px rgba(#000, .06)
	background: #fff


.c-list-11__header
	+min('m')
		display: flex

.c-list-11__date
	flex: none
	display: flex
	align-items: center
	flex-wrap: wrap
	align-content: center
	width: 80px
	height: 80px
	border-right: 1px solid #d1d1d1
	color: $base-color
	font-weight: $bold
	text-align: center
	line-height: 1

	+max('m')
		width: 100%


.c-list-11__mes
	width: 100%


.c-list-11__day
	width: 100%
	font-size: 4rem


.c-list-11__box
	flex: 1
	display: flex
	flex-wrap: wrap
	align-items: center
	align-content: center
	padding: 0 20px
	line-height: 1

	+max('m')
		padding: 20px


.c-list-11__name
	width: 100%
	margin-bottom: 5px
	color: $base2-color
	font-weight: $bold
	font-size: 1.8rem


.c-list-11__time
	width: 100%
	font-size: 1.4rem


.c-list-11__box-2
	padding: 15px


.c-list-11__p
	font-size: 1.3rem
	line-height: 1.4


.c-list-11__btn
	> *
		width: 100%
		justify-content: center
		margin-bottom: 10px


/* ^Fin - .c-list-11
-----------------------------------------------------------------------------*/





/* ^.c-list-12
-----------------------------------------------------------------------------*/

.c-list-12
	margin-bottom: 30px


.c-list-12__item
	margin-bottom: 10px

	&:last-child
		margin-bottom: 0

/* ^Fin - .c-list-12
-----------------------------------------------------------------------------*/
