/*----------------------------------------------------------------------------*
	$TABLES
*----------------------------------------------------------------------------*/


/**
 *
 * Eliminar el espacio generado entre celdas de una tabla.
 *
 */
table
	border-collapse: collapse
	border-spacing: 0
