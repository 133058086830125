/*----------------------------------------------------------------------------*
	$C-BLOCK
*----------------------------------------------------------------------------*/


/* ^Turbolinks
-----------------------------------------------------------------------------*/

.turbolinks-progress-bar
	height: 4px
	background-color: $base-color

/* ^Fin - Turbolinks
-----------------------------------------------------------------------------*/





/* ^.c-block-header
-----------------------------------------------------------------------------*/

.c-block-header
	position: fixed
	z-index: 100
	top: 0
	width: calc(100% - 300px)
	display: flex
	justify-content: space-between
	align-items: center
	height: 80px
	padding: 0 20px
	box-shadow: 0 0 10px rgba(#000, .2)
	background: #fff
	transition: all .2s ease

	+max('hd')
		width: 100%


.c-block-2--close-menu
	.c-block-header
		width: 100%


.c-block-header__logo
	img
		max-width: 230px

		+min('m')
			max-width: 260px

.c-block-header__nav
	position: relative
	display: flex
	align-items: center
	cursor: pointer

	+max('hd')
		display: none

	&:hover
		&:before
			color: $base-color

	&:before
		margin-right: 5px
		color: $base2-color
		font-size: 3rem
		transition: all .2s ease

	&:after
		position: absolute
		left: -8px
		content: ''
		width: 0
		height: 0
		border-right: 6px solid $base-color
		border-top: 6px solid transparent
		border-bottom: 6px solid transparent
		transition: all .2s ease

	span
		font-size: 1.5rem
		color: $base2-color
		transition: all .2s ease


.c-block-header__nav--movil
	@extends .c-block-header__nav

	+min('hd')
		display: none

	+max('hd')
		display: flex

	&:after
		transform: rotate(180deg)

	span
		+max('m')
			display: none


.c-block-header__nav--close
	&:after
		transform: rotate(180deg)


.c-block-header__nav--close-2
	&:after
		transform: rotate(0)




/* ^Fin - .c-block-header
-----------------------------------------------------------------------------*/





/* ^.c-block-1
-----------------------------------------------------------------------------*/

.c-block-1

	+min('xl')
		grid()
		height: 100vh

	+max('xl')
		padding: 30px 20px


.c-block-1__col-1-2

	&:first-child
		background: #2b3a4a

		+min('xl')
			col(1, 2, $g:0)

		+min('hd')
			col(5, 12, $g:0)

		+min(1501px)
			col(1, 2, $g:0)


	&:last-child
		display: flex
		justify-content: center
		align-items: center

		+min('xl')
			col(1, 2, $g:0)

		+min('hd')
			col(7, 12, $g:0)

		+min(1501px)
			col(1, 2, $g:0)


.c-block-1__modelo
	position: absolute
	z-index: 10
	bottom: 0
	left: calc(35% - 120px)
	transform: translateX(-35%)
	max-width: 600px


	+min(1291px)
		left: calc(35% - 120px)
		transform: translateX(-35%)
		max-width: 100%


	+min(1501px)
		left: calc(50% - 120px)
		transform: translateX(-50%)

	+max('hd')
		display: none

/* ^Fin - .c-block-1
-----------------------------------------------------------------------------*/





/* ^.c-block-2
-----------------------------------------------------------------------------*/

.c-block-2
	display: flex
	width: 100%
	min-height: 100vh
	transition: all .2s ease

	+max('hd')
		margin-left: -300px
		width: calc(100% + 300px)


.c-block-2--close-menu
	margin-left: -300px
	width: calc(100% + 300px)


.c-block-2--open-menu
	margin-left: 0 !important



// Columna 1
.c-block-2__menu
	flex: none
	position: fixed
	overflow: auto
	width: 300px
	height: 100vh
	background: #2f3a4c
	transition: all .2s ease



// Columna 2
.c-block-2__container
	flex: 1
	max-width: 100%
	padding-left: 300px
	padding-top: 80px
	background: #edeef2



.c-block-2__box
	padding: 0 20px

/* ^Fin - .c-block-2
-----------------------------------------------------------------------------*/





/* ^.c-block-3
-----------------------------------------------------------------------------*/

.c-block-3
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	height: 80px
	padding: 12px 20px
	background-color: $base-color
	color: #fff


.c-block-3__link
	display: inline-block
	margin-bottom: 5px
	color: #fff
	font-size: 1.3rem
	font-weight: $semi-bold

/* ^Fin - .c-block-3
-----------------------------------------------------------------------------*/





/* ^.c-block-4
-----------------------------------------------------------------------------*/

.c-block-4
	padding: 20px 20px 0 20px
	margin-bottom: 10px


.c-block-4__check
	padding-bottom: 15px


.c-block-4__card
	display: flex
	justify-content: center
	align-items: center
	padding: 10px 0
	margin-bottom: 10px


.c-block-4__card-img
	margin-right: 15px

	img
		display: block
		border-radius: 50%
		max-width: 70px


.c-block-4__card-name
	color: #fff
	font-weight: $bold
	font-size: 2.3rem


.c-block-4__card-member
	color: #b1bec6
	font-size: 1.5rem

	span
		display: inline-block
		padding: 1px 5px
		border-radius: 3px
		color: #fff
		font-weight: $semi-bold




.c-block-4__box-1
	margin-bottom: 20px


.c-block-4__title
	margin-bottom: 7px
	color: #fff
	font-weight: $bold
	font-size: 1.6rem


.c-block-4__list-2
	margin-bottom: 12px


.c-block-4__btn
	text-align: center


.c-list-4__permiso
	+max(560px)
		position: static
		border-left: none
		padding: 0 0 10px 0
		margin-bottom: 10px

/* ^Fin - .c-block-4
-----------------------------------------------------------------------------*/





/* ^.c-block-5
-----------------------------------------------------------------------------*/

.c-block-5
	width: 100%
	padding-bottom: 30px
	border-bottom: 1px solid #bbbdc3


.c-block-5--v2
	@extends .c-block-5
	border-bottom: none


.c-block-5__heading-1
	margin-bottom: 8px


.c-block-5__intro
	max-width: 600px
	margin: 0 auto
	text-align: center
	line-height: 1.5


.c-block-5__btn
	padding-top: 20px
	text-align: center

/* ^Fin - .c-block-5
-----------------------------------------------------------------------------*/





/* ^.c-block-6
-----------------------------------------------------------------------------*/

.c-block-6
	width: 100%
	padding: 30px 0
	text-align: center


.c-block-6__p
	max-width: 600px
	margin: 0 auto
	line-height: 1.5
	font-size: 1.5rem


.c-block-6__heading-2
	margin-bottom: 8px


/* ^Fin - .c-block-6
-----------------------------------------------------------------------------*/





/* ^.c-block-7
-----------------------------------------------------------------------------*/

.c-block-7
	padding-bottom: 30px

	+min('xl')
		grid()

.c-block-7__col-1
	+min('xl')
		col(8,12, $g:32)

	+max('xl')
		margin-bottom: 30px


.c-block-7__col-2
	padding: 20px
	border-left: 3px solid $base-color
	box-shadow: 0 2px 3px rgba(#000, .06)
	background: #fff

	+min('xl')
		col(4,12, $g:32)


.c-block-7__col-2--v2
	@extends .c-block-7__col-2
	border-left: 3px solid $base2-color



.c-block-7__heading-1
	margin-bottom: 25px
	color: $base2-color
	font-size: 2rem
	font-weight: $bold


.c-block-7__heading-2
	margin-bottom: 25px
	color: $base2-color
	font-size: 1.8rem
	font-weight: $bold

/* ^Fin - .c-block-7
-----------------------------------------------------------------------------*/





/* ^.c-block-8
-----------------------------------------------------------------------------*/

.c-block-8
	width: 100%

	+min('l')
		grid()

.c-block-8__col-1
	+min('l')
		col(5,12, $g:32)

	+min('xl')
		col(4,12, $g:32)

	+max('l')
		margin-bottom: 25px


.c-block-8__col-2
	+min('l')
		col(7,12, $g:32)

	+min('xl')
		col(8,12, $g:32)


.c-block-8__box
	padding: 20px
	margin-bottom: 10px
	box-shadow: 0 2px 3px rgba(#000, .06)
	background: #fff
	border-left: 3px solid $base-color



/* ^Fin - .c-block-8
-----------------------------------------------------------------------------*/





/* ^.c-block-9
-----------------------------------------------------------------------------*/

.c-block-9
	width: 100%
	padding: 15px
	margin-bottom: 50px
	background: #fff

/* ^Fin - .c-block-9
-----------------------------------------------------------------------------*/





/* ^.c-block-10
-----------------------------------------------------------------------------*/

.c-block-10
	border-radius: 8px
	background: #fff


.c-block-1__header
	display: flex
	justify-content: center
	align-items: center
	padding: 20px
	border-radius: 8px 8px 0 0
	background: $base-color
	color: #fff

	i
		font-size: 2.3rem


.c-block-1__heading
	margin-left: 6px
	font-weight: $bold
	font-size: 2.5rem


.c-block-10__box
	padding: 20px


.c-block-10__box--center
	@extends .c-block-10__box
	text-align: center


.c-block-10__btns
	> *
		margin: 5px

/* ^Fin - .c-block-10
-----------------------------------------------------------------------------*/





/* ^.c-block-11
-----------------------------------------------------------------------------*/

.c-block-11
	padding: 20px


.c-block-11__header
	display: flex
	justify-content: center
	padding-top: 10px
	margin-bottom: 15px
	// border-bottom: 1px solid #dddddd


.c-block-11__p
	font-size: 1.5rem
	line-height: 1.5
	text-align: justify

/* ^Fin - .c-block-11
-----------------------------------------------------------------------------*/





/* ^.c-block-12
-----------------------------------------------------------------------------*/

.c-block-12
	padding: 12px


.c-block-12__header
	padding-top: 8px


.c-block-12__video
	margin-bottom: 25px


/* ^Fin - .c-block-12
-----------------------------------------------------------------------------*/
