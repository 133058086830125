/*-----------------------------------------------------------*\
	$POPUPS
\*-----------------------------------------------------------*/


/* ^popup extend to vendors/magnific-popup.styl
-----------------------------------------------------------------------------*/

.mfp-content
	padding-bottom: 30px !important


// Fondo del overlay del popup
.mfp-bg

	&.mfp-popUp
		background: $base2-color !important


/* ^Fin - popup extend to vendors/magnific-popup.styl
-----------------------------------------------------------------------------*/





/* ^Loader
-----------------------------------------------------------------------------*/


.menu-mobile-overlay
	display: none
	position: fixed
	width: 100%
	height: 100%
	top: 0
	left: 0
	background: rgba($base2-color, 0.9)
	overflow: hidden
	z-index: 100


.loader-popup
	display: none
	position: fixed
	width: 100%
	height: 100%
	top: 0
	left: 0
	z-index: 101


.loader__box
	display: flex
	justify-content: center
	align-items: center
	position: fixed
	width: 100%
	height: 100%
	top: 0
	left: 0

	[class*="icon"]
		width: 30px
		height: @width
		font-size: 3rem
		line-height: 1
		color: #fff


.animate-spin
	animation: animate-spin .6s infinite linear


@keyframes animate-spin
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(359deg)

/* ^Fin - Loader
-----------------------------------------------------------------------------*/





/*	^.c-popup
-----------------------------------------------------------------------------*/

// Esta clase se agrega al body al abrir el popup
.c-popup-open
	overflow: hidden !important


.c-popup
	display: block
	position: relative
	margin: 50px auto 0 auto
	border-radius: 8px
	box-shadow: 0 0 20px rgba(#000, .7)
	// background: #fff

	+max('m')
		width: 90%

	+max(380px)
		width: 100%


.c-popup__header
	padding: 15px 35px
	border-radius: 8px 8px 0 0
	background: $base-color
	text-align: center
	font-size: 2rem
	font-weight: $medium
	color: #fff


.c-popup__container
	padding: 25px
	border-radius: 0 0 8px 8px
	background: #fff


/*	^Fin - .c-popup
-----------------------------------------------------------------------------*/





/*	^.c-popup--small
-------------------------------------------------------------*/

.c-popup--small-1
	@extend .c-popup
	max-width: 350px


/*	^Fin - .c-popup--small
-------------------------------------------------------------*/





/*	^.c-popup--md
-------------------------------------------------------------*/

.c-popup--md-1
	@extend .c-popup
	max-width: 550px


.c-popup--md-2
	@extend .c-popup
	max-width: 800px

	+max(860px)
		width: 92%


/*	^Fin - .c-popup--md
-------------------------------------------------------------*/
