/*----------------------------------------------------------------------------*
	$HEADING
*----------------------------------------------------------------------------*/


for key, val in $text-size

	.o-heading-{key}
		font-family: $header-font-family
		font-size: val
		font-weight: $medium

	.o-heading-{key}--center
		@extend .o-heading-{key}
		text-align: center




/* ^.o-heading-1
-----------------------------------------------------------------------------*/

.o-heading-1
	width: 100%
	max-width: 700px
	margin-left: auto
	margin-right: auto
	margin-bottom: 20px
	color: $base2-color
	font-size: 2.3rem
	text-align: center

	+min('m')
		font-size: 2.9rem


	i
		margin-right: 10px
		font-size: 2.5rem

		+min('m')
			font-size: 3.3rem

/* ^Fin - .o-heading-1
-----------------------------------------------------------------------------*/





/* ^.o-heading-2
-----------------------------------------------------------------------------*/

.o-heading-2
	width: 100%
	max-width: 600px
	margin-left: auto
	margin-right: auto
	margin-bottom: 20px
	color: $base2-color
	font-size: 1.8rem
	text-align: center

	+min('m')
		font-size: 2.4rem


.o-heading-2--v2
	@extends .o-heading-2

	+min('m')
		font-size: 2.2rem


/* ^Fin - .o-heading-2
-----------------------------------------------------------------------------*/
