/*----------------------------------------------------------------------------*
	$O-BAR
*----------------------------------------------------------------------------*/


/* ^o-bar
-----------------------------------------------------------------------------*/

.o-bar
	display: flex
	align-items: center


.o-bar__container
	flex: 1
	overflow: hidden
	height: 8px
	margin-right: 15px
	border-radius: 10px
	background: #dae5e7


.o-bar__completed
	background: $base-color
	height: inherit
	border-radius: 10px

.o-bar__percentage
	flex: none
	font-size: 1.4rem
	font-weight: $bold
	color: #fff

/* ^Fin - o-bar
-----------------------------------------------------------------------------*/
