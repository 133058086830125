/*----------------------------------------------------------------------------*
	$IMAGES
*----------------------------------------------------------------------------*/

/**
 *
 * Hacer que todas las imágenes sean fluidas y eliminar el borde que se presenta
 * en algunos navegadores al estar envuelto por un ancla.
 *
 */
 img
 	max-width: 100%
 	height: auto
 	border: none
