/*----------------------------------------------------------------------------*
	$NAV
*----------------------------------------------------------------------------*/


/* ^.c-nav-1
-----------------------------------------------------------------------------*/

// ul
.c-nav-1
	> li

		&.active
			a
				background: darken($base-color, 30%)

				&:hover
					padding-left: 20px
					background: darken($base-color, 30%)
					color: #fff

					&:before
						color: #fff

		> a
			position: relative
			display: flex
			padding: 16px 20px
			border-left: 1px solid transparent
			color: #fff
			font-size: 1.5rem

			&:hover,
			&.open
				padding-left: 30px
				background: #272f3e
				border-left-color: $base-color
				color: $base-color

				&:before
					color: $base-color

				i
					right: 30px


			&:before
				display: block
				width: 23px
				margin-right: 12px
				font-size: 2rem
				transition: all .2s ease

			i
				position: absolute
				right: 20px
				top: 50%
				transform: translateY(-50%)
				font-size: 1.2rem
				color: $base-color
				transition: all .2s ease

				&.active
					transform: rotate(180deg)
					top: calc(50% - 7px)


// ul
.c-nav-1__submenu
	display: none

	li
		a
			display: block
			padding: 13px 20px 13px 56px
			border-bottom: 1px solid #323c4f
			background: #272f3e
			font-size: 1.3rem
			color: #bbbbc4

			&:hover
				padding-left: 66px
				background: darken(#272f3e, 15%)
				color: $base-color

			&.active
				padding-left: 56px
				background: darken($base-color, 30%)
				color: #fff

/* ^Fin - .c-nav-1
-----------------------------------------------------------------------------*/





/* ^.c-nav-2
-----------------------------------------------------------------------------*/

.c-nav-2
	display: flex

	+max('l')
		display: none

	li
		margin-left: 20px

		&:first-child
			margin-left: 0

		a
			color: $base2-color
			font-weight: $semi-bold

			&:hover
				color: $base-color

			&:before
				margin-right: 8px

/* ^Fin - .c-nav-2
-----------------------------------------------------------------------------*/





/* ^.c-nav-3
-----------------------------------------------------------------------------*/

.c-nav-3
	display: flex
	flex-wrap: wrap
	width: 100%
	padding: 12px 0
	margin-bottom: 30px
	border-bottom: 2px solid $base2-color

	li
		&:before
			padding: 0 7px
			font-size: 1.1rem
			color: #acaaaa

		&:first-child
			&:before
				display: none

		a
			font-size: 1.3rem
			color: $base2-color

			&:hover
				text-decoration: underline

			&.active
				color: $base-color
				font-weight: $semi-bold

/* ^Fin - .c-nav-3
-----------------------------------------------------------------------------*/





/* ^.c-nav-4
-----------------------------------------------------------------------------*/

.c-nav-4
	margin-bottom: 20px

	&:last-child
		margin-bottom: 0

	li
		border-bottom: 1px solid #d2d2d2

		&:last-child
			border-bottom: none

		a
			display: block
			padding: 10px
			color: $base2-color
			font-size: 1.5rem

			&:hover
				background: #e9e7e7

			&.active
				background: $base-color
				color: #fff



/* ^Fin - .c-nav-4
-----------------------------------------------------------------------------*/





/* ^.c-nav-5
-----------------------------------------------------------------------------*/

.c-nav-5

	li
		margin-bottom: 10px

		a
			display: flex
			justify-content: space-between
			align-items: center
			padding: 14px 18px
			border: 1px solid transparent
			border-radius: 5px
			box-shadow: 0 2px 3px rgba(#000, .06)
			background: #fff
			font-size: 1.6rem
			color: $base2-color
			font-weight: $bold

			&:hover
				border-color: $base2-color

			&.active
				border-color: transparent
				background: $base-color
				color: #fff
				font-size: 1.7rem

				.c-nav-5__number
					background: #fff
					color: $base2-color


.c-nav-5__title
	display: flex
	align-items: center

	i
		margin-right: 5px
		font-size: 1.9rem


.c-nav-5__number
	display: inline-flex
	justify-content: center
	align-items: center
	width: 30px
	height: @width
	border-radius: 50%
	background: $base2-color
	color: #fff
	font-size: 1.5rem

/* ^Fin - .c-nav-5
-----------------------------------------------------------------------------*/
